#mdx-editor ul {
    list-style: disc !important;
    padding-left: 40px !important;
}

#mdx-editor ol {
    list-style: decimal !important;
    padding-left: 40px !important;
}

/* reset #mdx-editor style for h1-h6 */
#mdx-editor h1,
#mdx-editor h2,
#mdx-editor h3,
#mdx-editor h4,
#mdx-editor h5,
#mdx-editor h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-weight: bold;
    line-height: 1.2;
}

#mdx-editor h1 {
    font-size: 2em;
    /* other styles */
}

#mdx-editor h2 {
    font-size: 1.75em;
    /* other styles */
}

#mdx-editor h3 {
    font-size: 1.5em;
    /* other styles */
}

#mdx-editor h4 {
    font-size: 1.25em;
    /* other styles */
}

#mdx-editor h5 {
    font-size: 1em;
    /* other styles */
}

#mdx-editor h6 {
    font-size: 0.875em;
    /* other styles */
}
