// set variables
// Define CSS variables at the root level
:root {
  --purple: #6366f1;
  --purple-light: #e9e9fe;
  --purple-contrast: #4f46e5;
  --white: #fff;
  --gray-1: #e5e5e5;
  --gray-2: #3d251414;
  --gray-3: #a3a3a3;
  --black: #000;
  --shadow: 0 2px 5px rgba(0,0,0,0.1);
}


.tiptap {


  outline: none;

  :first-child {
    margin-top: 0;
    
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
    
    list-style-type: disc;

    ul {
      list-style-type: circle;
      margin: 0 0.4rem 0 0.4rem;
    }

  }

  ol {
    list-style-type: decimal;
  }

  /* Heading styles */
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    line-height: 1.1;
    margin-top: 1.5rem;
    text-wrap: pretty;
  }

  h1, 
  h2 {
    margin-top: 10px;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  h1 { 
    font-size: 1.4rem; 
  }

  h2 { 
    font-size: 1.2rem; 
  }

  h3 { 
    font-size: 1.1rem; 
  }

  h4, 
  h5, 
  h6 { 
    font-size: 1rem; 
  }

  /* Code and preformatted text styles */
  code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 1px solid var(--gray-3);
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: var(--gray-1);
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: var(--gray-2);
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: var(--purple);
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  .tableWrapper {
    margin: 1.5rem 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

 
}

 /* Bubble menu */
 .bubble-menu {
  background-color: var(--white);
  border: 1px solid var(--gray-1);
  border-radius: 0.7rem;
  box-shadow: var(--shadow);
  display: flex;
  padding: 0.2rem;
  transition: background-color 0.3s ease;
  gap: 0.2rem;
  font-size: 0.875rem;

  button {
    background-color: unset;

    border-radius: 0.3rem;

    padding: 0.1rem 0.425rem;

    &:hover {
      background-color: var(--gray-2);
    }

    &.is-active {
      background-color: var(--purple);

      &:hover {
        background-color: var(--purple-contrast);
      }
    }
  }
}

/* Floating menu */
.floating-menu {
  display: flex;
  background-color: var(--gray-2);
  padding: 0.1rem;
  border-radius: 0.5rem;
  // gap between buttons
  gap: 0.2rem;
  font-size: 0.875rem;
  button {
    background-color: unset;
    padding: 0.1rem 0.425rem;
    border-radius: 0.3rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--gray-3);
    }

    &.is-active {
      background-color: var(--white);
      color: var(--purple);

      &:hover {
        color: var(--purple-contrast);
      }
      }
    }
  }

.control-group {

  .button-group button {
      margin-right: 0.2rem;
      background: var(--gray-2);
      border-radius: .5rem;
      border: none;
      color: var(--black);
      font-family: inherit;
      font-size: .875rem;
      font-weight: 500;
      line-height: 1.15;
      margin: none;
      padding: .375rem .625rem;
      transition: all .2s cubic-bezier(.65,.05,.36,1);
    
  }

  .button-group button:hover {
    background-color: var(--gray-3);
  }

  .button-group button:active {
    background-color: var(--purple);
  }
}