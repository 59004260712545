#message-container ul {
    list-style: disc !important;
    padding-left: 40px !important;
}

#message-container ol {
    list-style: decimal !important;
    padding-left: 40px !important;
}

/* reset #message-container style for h1-h6 */
#message-container h1,
#message-container h2,
#message-container h3,
#message-container h4,
#message-container h5,
#message-container h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-weight: bold;
    line-height: 1.2;
}

#message-container h1 {
    font-size: 2em;
    /* other styles */
}

#message-container h2 {
    font-size: 1.75em;
    /* other styles */
}

#message-container h3 {
    font-size: 1.5em;
    /* other styles */
}

#message-container h4 {
    font-size: 1.25em;
    /* other styles */
}

#message-container h5 {
    font-size: 1em;
    /* other styles */
}

#message-container h6 {
    font-size: 0.875em;
    /* other styles */
}

