#datefilter-wrapper input{
    font-size: 16px;
    height: 34px;
    border-radius: 5px;
}

@media (min-width: 640px) {
    #datefilter-wrapper input{
        font-size: 12px;
    }
}
