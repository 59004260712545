.multi-job-form-loc-select__menu-portal {
    z-index: 80 !important;
}

.dsg-cell {
    font-size: 13px;
}

.dsg-context-menu {
    /* margin-left: -350px; */
}

.css-13cymwt-control {
    box-sizing: content-box !important;
}

#job-reminder-dialog .dropdown-content .item-renderer{
    align-items: center;
}


.dsg-container {
    height: 60vh !important;
}