#job-edit-form {
    z-index: 1000 ;
}


#location_wrapper input,
#job_type_wrapper input {
    font-size: 14px;
}
@media (max-width: 767px) {
    #location_wrapper input,
    #job_type_wrapper input {
        font-size: 16px !important;
    }
}