#job-edit-form {
    z-index: 1000;
}

.dropdown-content {
    z-index: 1000 !important;
}

.truncate {
    white-space: wrap;
}